<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-uppercase display-2 font-weight-black"
        >
          Footer Page
          <v-col>
            <v-btn color="primary" class="text-uppercase" @click="addnew">
              Add Footers
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog1" persistent>
        <BaseLoading :loading="loading" />
        <v-card>
          <v-card-title>
            <span class="headline">{{ activeItem }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="8">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title TH
                    </div>
                    <ckeditor
                      v-model="footer.title_th"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title Eng
                    </div>
                    <ckeditor
                      v-model="footer.title_en"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-col cols="12" v-if="this.modal == 'edit'">
                    <div class="text-capitalize headline mb-5">
                      Sort By
                    </div>
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12" v-if="this.modal == 'edit'">
                    <v-select
                      :items="sortBy"
                      label="Sort By"
                      outlined
                      required
                      v-model="footer.sort"
                      @change="updateIsNew"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Image Title
                    </div>
                    <v-divider light></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <!--         file for edit         -->
                    <v-file-input
                      v-if="this.modal == 'edit'"
                      v-model="fileSingle"
                      color="deep-purple accent-4"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      label="File input"
                      @change="uploadImage"
                      placeholder="Select your files"
                      prepend-icon="mdi-camera"
                      :show-size="1000"
                      :rules="fileSizeRules"
                    >
                      <template
                        v-slot:selection="{
                          index,
                          text,
                        }"
                      >
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }}
                          File(s)
                        </span>
                      </template>
                    </v-file-input>
                    <!--         file for edit         -->

                    <!--         file for craete         -->
                    <v-file-input
                      v-if="this.modal == 'new'"
                      :rules="fileRulesNews"
                      required
                      v-model="fileSingle"
                      color="deep-purple accent-4"
                      counter
                      accept="image/png, image/jpeg, image/bmp"
                      label="File input"
                      @change="uploadImage"
                      placeholder="Select your files"
                      prepend-icon="mdi-camera"
                      :show-size="1000"
                    >
                      <template
                        v-slot:selection="{
                          index,
                          text,
                        }"
                      >
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }}
                          File(s)
                        </span>
                      </template>
                    </v-file-input>
                    <!--         file for craete         -->
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-checkbox
                      class="pt-1 mt-0"
                      v-model="footer.is_square_thumbnail"
                      label="Square thumbnail"
                    >
                    </v-checkbox>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="create()"
              :disabled="!valid"
              v-if="this.modal == 'new'"
            >
              Save
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit()"
              :disabled="!valid"
              v-if="this.modal == 'edit'"
            >
              Save Edit
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <BaseLoading :loading="loading" />
      <div class="text-capitalize display-1 my-5">Footer</div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        outlined
        label="Search"
        hide-details
      ></v-text-field>
      <v-data-table
        dark
        fixed-header
        height="500"
        :headers="headers"
        :items="footers"
        :items-per-page="pageSize"
        :page.sync="page"
        @page-count="pageCount = $event"
        :search="search"
        :sort-desc="['id']"
        hide-default-footer
        class="elevation-1 my-5"
      >
        <template v-slot:item.is_square_thumbnail="{ item }">
          {{ item.is_square_thumbnail ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </v-container>
  </div>
</template>

<script>
import FooterMenuServices from '@/services/FooterMenuServices'
import { FormMixin } from '@/Mixins/FormMixin'

export default {
  mixins: [FormMixin],
  data() {
    return {
      headers: [
        { text: 'Title_th', value: 'title_th', align: 'start' },
        { text: 'Title_en', value: 'title_en', align: 'start' },
        {
          text: 'Square Thumbnail',
          value: 'is_square_thumbnail',
          align: 'start',
        },
        { text: 'Sort By', value: 'sort', align: 'start', width: '1%' },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],
      footer: {
        sort: null,
        title_en: '',
        title_th: '',
        is_square_thumbnail: false,
      },
    }
  },
  created() {
    this.readData()
  },
  methods: {
    next() {
      this.readData()
    },
    readData() {
      FooterMenuServices.getFooterMenus()
        .then(res => {
          if (res.status === 200) {
            this.footers = []
            this.sortBy = []
            this.footers = res.data
            for (var i = 0; i <= res.data.length; i++) {
              this.sortBy.push(i + 1)
            }
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })
    },
    create() {
      if (this.$refs.form.validate()) {
        this.loading = !this.loading
        let formData = new FormData()
        formData.append('image', this.file)
        formData.append('titleTH', this.footer.title_th)
        formData.append('titleEN', this.footer.title_en)
        formData.append('isSquareThumbnail', this.footer.is_square_thumbnail)

        FooterMenuServices.createFooter(formData)
          .then(() => {
            this.$swal.fire({
              icon: 'success',
              title: 'News created successfully',
            })
            this.loading = !this.loading
            this.dialog1 = false
            this.resetData()
            this.readData()
          })
          .catch(err => {
            this.$swal.fire({
              icon: 'error',
              title: `${err.response.data.message}`,
            })
            this.loading = !this.loading
            if (err.response.status === 401) {
              this.removeToken()
              this.$router.push({ name: 'Admin' })
            }
          })
      }
    },
    resetData() {
      this.footer = {
        sort: null,
        title_en: '',
        title_th: '',
        is_square_thumbnail: false,
      }
      this.fileSingle = null
      this.file = ''
    },
    addnew() {
      this.modal = 'new'
      this.activeItem = 'New Footers'
      this.resetData()
      this.dialog1 = true
    },
    editItem(footer) {
      this.modal = 'edit'
      this.activeItem = 'Edit Footers'
      this.footer = footer
      this.dialog1 = true
    },
    deleteItem(doc) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            FooterMenuServices.deleteFooter(doc.id)
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: 'Footers delete in successfully',
                })
                this.loading = !this.loading
                this.readData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
    edit() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, edit it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            let formData = new FormData()
            formData.append('image', this.file)
            formData.append('titleTH', this.footer.title_th)
            formData.append('titleEN', this.footer.title_en)
            formData.append('sort', this.footer.sort)
            formData.append(
              'isSquareThumbnail',
              this.footer.is_square_thumbnail,
            )

            FooterMenuServices.updateFooter(this.footer.id, formData)
              .then(() => {
                this.dialog1 = false
                this.$swal.fire({
                  icon: 'success',
                  title: 'Footers edited successfully',
                })
                this.loading = !this.loading
                this.resetData()
                this.readData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
    updateIsNew(e) {
      this.footer.sort = e
    },
  },
}
</script>

<style></style>
