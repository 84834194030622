<template>
  <div>
    <FooterTable />
  </div>
</template>

<script>
import FooterTable from '@/components/Admin/ContentFooter/FooterTable'
export default {
  name: 'Footers',
  components: { FooterTable },
}
</script>

<style scoped></style>
